export const welcome = [
  `Welcome to my terminal! ${String.fromCodePoint(0x1f44b)}`,
  "",
];
export const help = [
  "Controls:-",
  `${String.fromCodePoint(0x1f7e2)} to maximise or restore`,
  `${String.fromCodePoint(0x1f7e1)} to minimise`,
  `${String.fromCodePoint(
    0x1f534
  )} to close this page and open my LinkedIn Page`,
  `Hint: Try using the ${String.fromCodePoint(
    0x1f7e2
  )} button if the CV is too long!`,
  "",
  "Commands:",
  "'cv' - Print my CV to this terminal",
  "'cv --download' - Download my CV in text format",
  "'links' - Get a list of my links",
  "'certs' - Get a list of my certifications",
  "'help' - Print this list of commands",
  "'clear' - Clear the terminal",
];

export const cv = [
  "Nathan Wall - Software Engineer",
  "===============================",
  "Summary",
  "------------------------------",
  "Full-stack software engineer in the 'Tech for Good' space, with over a decade of",
  "experience in the software industry.",
  "Dedicated, hard-working, and passionate about software, with a penchant for",
  "readable, maintainable, scalable and robust code.",
  "",
  "Skills",
  "------------------------------",
  "  Languages:-",
  "    Python, Javascript, Perl, Shell/BASH script, SQL, JSON, XML, YAML, GraphQL",
  "  Databases:-",
  "    MongoDB, IBM Informix Dynamic Server, MySQL, PostgreSQL, DataBricks",
  "  Utilities:-",
  "    Git, Docker, Kubernetes, AWS, Linux, Windows, MacOS",
  "  Frameworks:-",
  "    Frontend:-",
  "      React, Jinja2, Bootstrap, HTML5",
  "    Backend:-",
  "      FastAPI, Flask, Node.js",
  "    Testing:-",
  "      Pytest, Behave, Gherkin",
  "  Other:-",
  "    Agile, Scrum, Kanban, TDD, BDD, CI/CD, DevOps",
  "",
  "Experience",
  "------------------------------",
  "Role: Developer",
  "Where: Aire Logic Limited",
  "When: August 2022 to present",
  "Duties:-",
  "  - Contracted to the NHS as a developer to work in the API Management team",
  "    to build and maintain APIs in AWS Lambda, API Gateway, and DynamoDB",
  "  - Building an internal AI platform using GPT 3.5 in React, FastAPI",
  "    to assist with tendering for contracts and internal policy queries",
  "  - Contracted to the NHS as a Tech Lead to build a greenfield ",
  "    Vaccinations FHIR API proof of concept",
  "  - Contracted to the NHS as a Data Engineer to work on vaccinations data",
  "    pipelines using DataBricks data lake and Apache Spark for ETL processes",
  "",
  "Role: Security Software Engineer",
  "Where: ANS Group Limited",
  "When: November 2021 to August 2022",
  "Duties:-",
  "  - Developing secure and scalable micro-services to manage and parse data",
  "    between other internal services using Python and FastAPI",
  "  - Maintenance and administration the Elastic Stack (Elasticsearch, Logstash,",
  "    Kibana) for log aggregation and analysis",
  "  - Refactoring the existing codebase to make it more performant, maintainable",
  "    and scalable",
  "  - Creation and deployment of software using GitLab CI/CD pipelines",
  "  - Working with the DevOps team to deploy and maintain software in Kubernetes",
  "  - Creation of tests for automated testing using Pytest",
  "",
  "Role: Software Engineer",
  "Where: SSP Limited",
  "When: July 2018 to November 2021",
  "Duties:-",
  "  - Specialist in developing FourJs desktop and server applications in an agile",
  "    environment",
  "  - Creation and maintenance of a team web application in Python using the Flask",
  "    web framework",
  "  - Developing secure, intermediary Python programs to communicate with external",
  "    AWS services",
  "  - Interrogation, configuration and management of relational databases using",
  "    SQL",
  "  - Database and application migrations from legacy systems to newer, more",
  "    modern technologies",
  "  - Creating and configuring development and QA server environments",
  "  - Creating and managing backup routines for development environments",
  "  - Using Subversion for version control and code merges",
  "  - 3rd line application support",
  "",
  "Role: Associate Software Engineer",
  "Where: SSP Limited",
  "When: June 2017 to July 2018",
  "Duties:-",
  "  - Code changes to desktop and server applications in an agile environment at a",
  "    junior level",
  "  - Interrogation of relational databases using SQL",
  "  - Creating and configuring development and QA server environments",
  "  - Creating and managing backup routines for development environments",
  "  - Using Subversion for version control and code merges",
  "  - 2nd line application support",
  "",
  "Role: Software Test Engineer",
  "Where: SSP Limited",
  "When: April 2014 to June 2017",
  "Duties:-",
  "  - QA testing Informix 4gl and Fourjs applications",
  "  - Writing and executing test plans and test cases",
  "  - Regression testing",
  "  - Linux and Unix server maintenance",
  "  - Second line application support",
  "",
  "Role: Support Analyst",
  "Where: SSP Limited",
  "When: April 2011 to April 2014",
  "Duties:-",
  "  - First and second line application support",
  "  - Implementing emergency fixes to production systems",
  "  - Linux and Unix server maintenance",
  "------------------------------",
  "References available on request",
  "------------------------------",
];

export const links = [
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com/in/nathan-w-68022981",
  },
  {
    name: "GitHub",
    url: "https://github.com/nathanwall",
  },
];

export const certs = [
  {
    name: "Apollo (GraphQL): Graph Developer - Associate",
    url: "https://www.apollographql.com/tutorials/certifications/4e880ce8-b7bb-442f-9a8d-c5b48c351ef1",
  },
  {
    name: "Microsoft: Introduction to Python: Unit 1",
    url: "https://courses.edx.org/certificates/a7962e3c9694458f9849cf018e5fdbe1",
  },
  {
    name: "Microsoft: Introduction to Python: Fundamentals",
    url: "https://courses.edx.org/certificates/a4a34bd9acc84bce8ea637252399f096",
  },
  {
    name: "Linux Foundation: Introduction to Linux",
    url: "https://s3.amazonaws.com/verify.edx.org/downloads/fff3257f1e8545638d7b74e00c5d4d17/Certificate.pdf",
  },
];
